<template>
  <div class="manage-comments" :class="{skeleton:!state.loaded}">
    <div class="wrapper" v-if="Array.isArray(state.comments) && state.comments.length">
      <Comment
          v-for="(c, idx) in state.comments" :key="idx"
          :commentSeq="c.commentSeq"
          :content="c.content"
          :commentBlindSeq="c.commentBlindSeq"
          :createDate="c.createDate"
          :createId="c.createId"
          :change="load"
          :delFlag="c.delFlag"
          :depth="Number(c.depth)"
          :groupNum="c.groupNum"
          :investSeq="c.investSeq"
          :investorSeq="c.investorSeq"
          :memberSeq="c.memberSeq"
          :memberName="c.memberName"
          :memberAuth="c.memberAuth"
          :memberType="c.memberType"
          :page="page"
          :projectType="$route.params.projectType"
          :projectSeq="Number($route.params.projectSeq)"
          :pageIndex="state.args.pageIndex"
          :restoreFlag="c.restoreFlag"
          :skeleton="!state.loaded"
          :orderNum="c.orderNum"
          :openId="project.openId || project.memberSeq"
          :totalAmt="c.totalAmt"
          :updateDate="c.updateDate"
      />
      <div class="page">
        <Pagination :info="state.paginationInfo" :change="changePage" v-if="state.paginationInfo"/>
      </div>
    </div>
    <NoData v-else/>
  </div>
</template>

<script>
import {defineComponent, reactive, ref, watch} from "@vue/composition-api";
import http from "@/scripts/http";
import Pagination from "@/components/Pagination.vue";
import mixin from "@/scripts/mixin";
import router from "@/scripts/router";
import MemberPicture from "@/components/MemberPicture";
import NoData from "@/components/NoData";
import Comment from "@/components/Comment";

function Component(initialize) {
  this.name = "pageManageComments";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Comment, NoData, MemberPicture, Pagination},
  props: {
    project: Object,
    page: String,
  },
  setup(props) {
    const component = new Component(() => {
      for (let i = 0; i < state.args.pageUnit; i += 1) {
        state.comments.push({
          content: "Please wait a moment. Please wait a moment.",
          createDate: "0000-00-00 00:00",
          memberName: "Wait",
          children: []
        });
      }

      load();
    });

    const state = reactive({
      loaded: false,
      args: {
        pageIndex: 1,
        pageSize: 5,
        pageUnit: 30
      },
      paginationInfo: null,
      comments: [],
    });

    const commentTextareaRef = ref();

    const projectType = router.app.$route.params.projectType;
    const projectSeq = Number(router.app.$route.params.projectSeq);

    const load = (pageIndex, preventScroll) => {
      state.pageIndex = pageIndex || window.Number(router.app.$route.query.page?.toString()) || 1;

      !preventScroll && window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });

      state.loaded = false;
      state.args.pageIndex = state.pageIndex;

      http.get(`/api/maker/project/${projectType}/${projectSeq}/${props.page}`, state.args).then(({data}) => {
        state.loaded = true;
        // state.comments = [];
        state.comments = data.body.list;

        // 후원자 응원글인 경우에는 API ResponseDTO가 다르다.
        if (props.page === "cheer") {
          state.comments.forEach(e => {
            e.groupNum = e.investorSeq;
          });
        }

        state.paginationInfo = data.body.paginationInfo;
      });
    };

    const changePage = (page) => {
      router.push({
        query: {page}
      });
    };

    watch(() => router.app.$route.query, (next, prev) => {
      if (JSON.stringify(next) === JSON.stringify(prev) || (next.modals || prev.modals)) {
        return;
      }

      load();
    });

    return {component, state, commentTextareaRef, load, changePage};
  },
});
</script>

<style lang="scss" scoped>
.manage-comments {
  color: $colorTextDefault;
  padding-top: $px15;

  > .wrapper {
    .list {
      > ul > li {
        margin-bottom: $px30;

        > .wrapper {
          position: relative;
          padding-left: $px47;
          padding-right: $px47;

          .img {
            width: $px35;
            height: $px35;
            position: absolute;
            top: $px3;
            left: 0;
            border: $px1 solid #eee;
            border-radius: 50%;
          }

          .root {
            b {
              margin-right: $px6;
            }

            .actions {
              position: absolute;
              top: 0;
              right: 0;

              .btn {
                margin-left: $px4;
                margin-right: 0;
                width: $px40;
                padding-left: 0;
                padding-right: 0;
              }
            }
          }

          .content {
            margin-top: $px5;
            word-break: break-all;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        &[data-depth="1"] {
          padding-left: $px47;
          position: relative;

          > .wrapper {
            padding-right: $px94;
          }

          &:before {
            content: " ";
            background-image: url(/assets/img/page.project.detail.comment.pipe.svg);
            background-size: 100% 100%;
            width: $px20;
            height: $px20;
            position: absolute;
            top: $px5;
            left: $px15;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .page {
      padding-top: $px5;
    }
  }

  &.skeleton {
    > .wrapper .list li {
      .img, .root b, .root > span, .root .actions .btn, .content > span {
        @include skeleton;
      }
    }
  }
}
</style>